import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from "../components/layout"
import styled from 'styled-components'
import Seo from "../components/seo"
import Banner from "../components/banner"

class ServiceTemplate extends React.Component {
  render(data) {

  const service = get(this.props, 'data.nodeService');

    return (
      <Layout>
      <Seo title={service.title} />

       <Banner title={service.title} />

      <MainWrapper>
        <div className="body" dangerouslySetInnerHTML={{ __html: service.field_body_text.processed }} />
	    </MainWrapper>     

      </Layout>
    ) 
  }
}

export default ServiceTemplate

export const PageQuery = graphql`
  query ServicePageBySlug($serviceId: String!) {
    site {
      siteMetadata {
        title
      }
    }

    nodeService(id: { eq: $serviceId }) {
	    created(formatString: "dddd Do, MMMM YYYY")
	    title
	    id
      field_body_text {
	      value
	      processed
	    }
  	 }
   }
 `

 const MainWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-wrap: wrap;

    a {
      color: red;
    }
  }
 `